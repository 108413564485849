import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Nav, Tab } from "react-bootstrap"
import Layout from "../components/layout"

const MenuPage = () => {
  const data = useStaticQuery(graphql`
		{
			menu: allContentfulMenuCategory {
				edges {
					node {
						categoryTitle
						id
						icon {
							file {
								url
						}
					 }
					}
				}
			}
			food: allContentfulMenuCategory {
				edges {
					node {
						categoryTitle
						id
						menuSection {
							sectionTitle
							sectionDescription
							sectionPrice
							vegan
							vegetarian
							menuItems{
									... menuItems
								... visualMenu
							}
						}
					}
				}
			}
		}
		
		fragment menuItems on ContentfulListMenuItem {
			itemTitle
			comboNumber
			vegan
			vegetarian
		}
		fragment visualMenu on ContentfulVisualMenuItem {
			menuItemTitle
			menuItemPrice
			menuItemDescription
			vegan
      vegetarian
			menuItemImage {
				fluid{
					src
				}
			}
		}`)

console.log('data', data.menu.edges)   

	const menu = data.menu.edges;
	const food = data.food.edges;

	const hookload = () => {
		const url = typeof window !== 'undefined' ? window.location.hash : '';
		console.log(url)

		menu.map((m, k) => {
			menu[k].node.slug = m.node.categoryTitle.toLowerCase().toString().replace(/[^a-z\.]+/g, '-');
		})
		const hash = menu.filter(m => m.node.slug === url.replace('#',''));
		console.log('hash', hash)
		if(url) {
				return hash[0].node.id
			}else {
				return menu[0].node.id
		}
	}

	return ( <Layout>
		<div className="jumbotron menu">
				<h1>Menu</h1>
				<p></p>
		</div>
		<div><Tab.Container defaultActiveKey={hookload()}>
				<div className="container menu">
						<div className="row">
								<div className="col-md-3">
										<div className="boxed-menu">
													<Nav variant="tabs" as="ul" className="food-menu-navigation menu-nav">
														{ menu.map((m, mkey) => {
															const cateTitle =m.node.categoryTitle.toLowerCase().toString().replace(/[^a-z\.]+/g, '-');
														return <Nav.Item key={mkey}><Nav.Link href={`#${cateTitle}`} eventKey={m.node.id}>{ m.node.icon ? <img src={m.node.icon.file.url } alt={m.node.categoryTitle} /> : '' }{m.node.categoryTitle}</Nav.Link></Nav.Item>
														})}
													</Nav>
													<hr />
													<ul className="menu-key">
														<li className="vegetarian"> - Vegetarian</li>
														<li className="vegan"> - Vegan</li>
														<li className="frenchie"> - Frenchie Style <br />(fries under your food) <span className="addOn">+ 2.00</span></li>
													</ul>
										</div>
										<p className="instagram-callout d-none d-md-block">Follow us on Instagram for secret menu items!</p>
										<a className="btn btn-primary instagram d-none d-md-block" type="button" href="https://www.instagram.com/pokezmexicanrestaurant/?hl=en">Open Instagram</a>
										</div>
								<div className="col-md-9">
									<Tab.Content>
										{ food.map((f, fKey) => {
											return (<Tab.Pane eventKey={f.node.id} key={fKey}>
												{f.node.menuSection.map((item, itemKey) => {
													return (
														<div className="menu-section" key={itemKey}>
															<h2 className={`section-title ${ item.vegan ? 'vegan' : ''} ${ item.vegetarian ? 'vegetarian' : ''}`}>{ item.sectionTitle }</h2>
															<p className="section-description">{ item.sectionDescription }</p>
															<p className="section-price">{ item.sectionPrice }</p>
															<div className="card-group">
																{ item.menuItems.map((el, elKey) => {
																	if(el.__typename === 'ContentfulListMenuItem') {
																		return (
																			<ol className={`list-menu-items ${ item.sectionTitle ? item.sectionTitle : ''}`} key={elKey}>
																					<li className="list-item-title"><span className="combo-number">{ el.comboNumber }</span> { el.itemTitle }</li>
																			</ol>)
																	}else {
																		return (
																			<div className="card menu-item" key={elKey}>
																				{ el.menuItemImage && <img className="card-img-top w-100 d-block" src={ el.menuItemImage.fluid.src } /> }
																				<div className="card-body">
																					<h4 className={`card-title bottom ${ el.vegan ? 'vegan' : ''} ${ el.vegetarian ? 'vegetarian' : ''} ${ el.frenchie ? 'frenchie' : ''}`}><span>{el.menuItemTitle}</span> <span className="menu-item-price bottom">{ el.menuItemPrice }</span></h4>
																					<div className="hover">
																						<h4 className="card-title"><span>{el.menuItemTitle}</span> <span className="menu-item-price">{ el.menuItemPrice }</span></h4>
																						<p>{ el.menuItemDescription }</p>
																					</div>
																				</div>
																			</div>
																		)
																	}
																})}
																
															</div>
														</div>
													)
												})}
											</Tab.Pane>)
										})}
									</Tab.Content>
								</div>
						</div>
					</div>
				</Tab.Container>
			</div>
	</Layout>)
}

export default MenuPage;